import Image from "next/image";
import styled from "styled-components";
import { device } from "../layout/global-styles/device";
import ReactHtmlParser from "react-html-parser";

interface FullscreenImageStyle {
    noMargin?: string;
    contained?: boolean;
    onlyMobile?: boolean;
}

const FullscreenImage = ({
    data,
    size,
    nomargin = null,
    contained = false,
    onlyMobile = false,
}) => {
    const { mainImage, mobileImage, text } = data;
    const { width, height, mWidth, mHeight } = size;

    return (
        <Wrapper
            noMargin={nomargin}
            contained={contained}
            onlyMobile={onlyMobile}
        >
            {!contained && (
                <LargeImage contained={contained}>
                    <Text>{ReactHtmlParser(text)}</Text>
                    <Image
                        unoptimized={true}
                        src={mainImage}
                        alt={"Usp på megane"}
                        layout="fill"
                        objectFit="cover"
                    />
                </LargeImage>
            )}
            <MainImage contained={contained}>
                <Text>{ReactHtmlParser(text)}</Text>
                <Image
                    unoptimized={true}
                    src={mainImage}
                    alt={"Usp på megane"}
                    width={width}
                    height={height}
                />
            </MainImage>
            <MobileImage>
                <Image
                    unoptimized={true}
                    src={mobileImage}
                    alt={"Usp på megane"}
                    width={mWidth}
                    height={mHeight}
                />
            </MobileImage>
        </Wrapper>
    );
};

export default FullscreenImage;

const Wrapper = styled.section<FullscreenImageStyle>`
    width: ${(props) => (props.contained ? "80%" : "100vw")};
    margin: ${(props) => (props.noMargin ? props.noMargin : "6rem auto 0")};
    position: relative;

    @media ${device.tablet} {
        display: ${(props) => (props.onlyMobile ? "none" : "block")};
    }
`;

const Text = styled.h2`
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);

    color: ${(props) => props.theme.white};
    font-family: "nouvelr-bold";
    font-size: 5rem;
    z-index: 100;
`;

const MainImage = styled.div<FullscreenImageStyle>`
    display: none;
    position: relative;
    justify-content: center;

    @media ${device.tablet} {
        display: flex;
    }
    @media ${device.desktop} {
        display: ${(props) => (props.contained ? "flex" : "none")};
    }
`;

const LargeImage = styled.div<FullscreenImageStyle>`
    display: none;
    position: relative;

    @media ${device.desktop} {
        display: ${(props) => (props.contained ? "none" : "block")};
        width: 100vw;
        min-height: 80vh;
    }
`;

const MobileImage = styled.div`
    position: relative;
    max-width: 100vw;
    display: flex;

    @media ${device.tablet} {
        display: none;
    }
`;
