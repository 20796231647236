import React from "react";

import styled from "styled-components";
import ArrowRight from "../icons/ArrowRight";

const FlexWrapper = styled.a<PrimaryLinksStyledComponent>`
    display: flex;
    align-items: center;
    max-width: 350px;
    text-decoration: none;
    color: ${(props) => (props.light ? props.theme.white : props.theme.black)};
`;

const Text = styled.p<PrimaryLinksStyledComponent>`
    padding-right: 5px;
    padding-bottom: 3px;
    text-decoration: none;
    color: ${(props) => (props.light ? props.theme.white : props.theme.black)};
    text-transform: lowercase;
    font-family: "nouvelr-bold";
    font-size: 1.2em;
`;

interface PrimaryLinkProps {
    text: string;
    href?: string;
    light?: boolean;
    blank?: boolean;
}

interface PrimaryLinksStyledComponent {
    light?: boolean;
}

const PrimaryLink: React.FC<PrimaryLinkProps> = ({
    text,
    href,
    light,
    blank = false,
}) => {
    return (
        <>
            {!blank && (
                <FlexWrapper href={href}>
                    <Text light={light}>{text}</Text>
                    <ArrowRight fill={"#EFDF00"} width={20} height={20} />
                </FlexWrapper>
            )}
            {blank && (
                <FlexWrapper href={href} target="_blank" rel="noreferrer">
                    <Text light={light}>{text}</Text>
                    <ArrowRight fill={"#EFDF00"} width={20} height={20} />
                </FlexWrapper>
            )}
        </>
    );
};

export default PrimaryLink;
