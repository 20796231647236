import Image from "next/image";
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import { device } from "../layout/global-styles/device";
import { formatters } from "../lib/formatters";
import { LinkData } from "../lib/LinkData";
import ModelSection from "../lib/modelsection";
import AlternativLink from "./Elements/AlternativLink";
import PrimaryButton from "./Elements/PrimaryButton";
import Title from "./Elements/Title";
import PageOvertake from "./PageOvertake";

export interface TripleColumnWithOvertakeProps {
    data: CardData[];
    id?: string;
    title?: string;
    url?: string;
    equipment?: boolean;
    accessory?: boolean;
}
export interface CardData {
    image: string;
    title: string;
    text?: string;
    cta?: LinkData;
    date?: string;
}

const TripleColumnWithOvertake: React.FC<TripleColumnWithOvertakeProps> = ({
    data,
    id = "",
    title = undefined,
    url = "",
    equipment = false,
    accessory = false,
}) => {
    const [showModal, setShowModal] = useState(
        process.browser ? ModelSection.isAccessories() : false
    );

    useEffect(() => {
        if (showModal === false && ModelSection.isAccessories()) {
            history.back();
        }
    }, [showModal]);

    const hashchange = () => {
        setShowModal(ModelSection.isAccessories());
    };

    useEffect(() => {
        window.addEventListener("hashchange", hashchange);
        return () => {
            window.removeEventListener("hashchange", hashchange);
        };
    }, []);

    const highlightedData = data.slice(0, 3);
    const remainingData = data.slice(3);

    return (
        <SectionWrapper id={id}>
            {title && <Title heading={title} />}
            <FlexWrapper>
                {highlightedData &&
                    highlightedData.length &&
                    highlightedData.map((c) => (
                        <FlexContent key={c.title}>
                            <ImageDiv>
                                <Image
                                    objectFit="cover"
                                    unoptimized={true}
                                    alt={formatters.stripHtmlTags(c.title)}
                                    src={c.image}
                                    placeholder="blur"
                                    blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                                    width={460}
                                    height={280}
                                />
                            </ImageDiv>
                            <FlexItem>
                                <Heading>{ReactHtmlParser(c.title)}</Heading>
                            </FlexItem>
                            {c.text && (
                                <FlexItem>
                                    <Text>{ReactHtmlParser(c.text)}</Text>
                                </FlexItem>
                            )}
                        </FlexContent>
                    ))}
            </FlexWrapper>
            {remainingData && remainingData.length && (
                <CtaWrapper>
                    <Cta
                        onClick={() => {
                            window.location.hash = url;
                            setShowModal(true);
                        }}
                    >
                        {accessory && <AlternativLink text={"mer tilbehør"} />}
                        {equipment && (
                            <PrimaryButton text={"se mer utstyr her"} />
                        )}
                    </Cta>
                </CtaWrapper>
            )}
            <PageOvertake
                data={data}
                accessoriesData={remainingData}
                showModal={showModal}
                setShowModal={setShowModal}
                componentType={"product"}
            />
        </SectionWrapper>
    );
};

const SectionWrapper = styled.section`
    display: flex;
    flex-direction: column;
`;

const FlexWrapper = styled.div`
    display: flex;
    max-width: 1440px;
    width: 70vw;
    flex-direction: column;
    justify-content: "space-around";
    margin: 1rem auto;
    padding: 0;

    @media ${device.laptop} {
        flex-direction: row;
    }
`;

const FlexContent = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0% auto 2% auto;
    margin-bottom: 2rem;
    width: 100%;
    max-width: 100vw;
    max-width: 100%;
    flex-basis: 30%;

    @media ${device.mobileL} {
        width: 33.3333%;
        width: 460px;
    }

    @media ${device.laptop} {
        margin: 0% 15px 0% 15px;
    }
`;
const FlexItem = styled.div`
    margin: 0% 0% 4% 0%;
    padding: 0;

    @media ${device.laptop} {
        padding: 0;
    }
`;

const ImageDiv = styled(FlexItem)`
    position: relative;
    width: 100%;

    @media ${device.laptop} {
        max-width: 460px;
    }
`;

const Heading = styled.h3`
    font-family: "nouvelr-bold";
    text-align: left;
    padding: 0;
`;

const Text = styled.div`
    max-width: 90%;
    padding: 0;

    .duty-text {
        font-size: 0.7rem;
        margin-top: 0.5rem;
    }

    @media ${device.tablet} {
        padding: 0;
    }
`;

const CtaWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const Cta = styled.div`
    cursor: pointer;
`;

export default TripleColumnWithOvertake;
