import styled from "styled-components";
import { ButtonProps, ButtonStyledComponent } from "./SuperPrimaryButton";

const Button = styled.button<ButtonStyledComponent>`
    min-width: 146px;
    font-family: "nouvelr-bold";
    color: ${(props) => props.theme.black};
    background-color: ${(props) => props.theme.white};
    border: 1px solid ${(props) => props.theme.black};
    text-decoration: none;
    text-transform: lowercase;
    line-height: ${(props) => (props.menu ? "1" : "unset")};

    vertical-align: middle;

    padding: 14px 15px;
    font-size: ${(props) => (props.menu ? "12px" : "1.1rem")};

    cursor: pointer;
    transition: 200ms ease;

    &:hover {
        color: ${(props) => props.theme.black};
        background-color: ${(props) => props.theme.primary};
        border: 1px solid ${(props) => props.theme.primary};
    }
`;

const TertiaryButton = ({ text, menu }: ButtonProps) => {
    return <Button menu={menu}>{text}</Button>;
};

export default TertiaryButton;
