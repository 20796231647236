import React from "react";
import styled from "styled-components";
import Title from "../Elements/Title";
import FullscreenImage from "../FullscreenImage";
import TripleColumnWithOvertake from "../TripleColumnWithOvertake";
import VideoComponent from "../VideoComponent";
import ReactHtmlParser from "react-html-parser";
import { useCallback, useEffect, useState } from "react";
import PrimaryButton from "../Elements/PrimaryButton";
import { device } from "../../layout/global-styles/device";
import Image from "next/image";
import Link from "next/link";
import AlternativLink from "../Elements/AlternativLink";

interface EquipemntOverallStyled {
    narrow?: boolean;
}

const EquipmentOverall = ({ data, narrow = false }) => {
    const {
        video,
        mobileVideo,
        image,
        equipment,
        title,
        description,
        overlay,
        press,
    } = data;

    const [showModal, setShowModal] = useState(false);

    const keyPress = useCallback(
        /* Close modal when ESC key is pressed */
        (e) => {
            if (e.key === "Escape" && showModal) {
                setShowModal(false);
            }
        },
        [setShowModal, showModal]
    );

    useEffect(() => {
        /* Call and cleanup function */
        document.addEventListener("keydown", keyPress);
        return () => {
            document.removeEventListener("keydown", keyPress);
        };
    }, [keyPress]);

    return (
        <>
            <Wrapper narrow={narrow}>
                {!title && <Title heading={"utstyr"} />}
                <TextWrapper>
                    {title && <Heading>{ReactHtmlParser(title)}</Heading>}
                    {description && (
                        <Description>
                            {ReactHtmlParser(description)}
                        </Description>
                    )}
                    {press && (
                        <PressWrapper>
                            {press.map((c, idx) => {
                                return (
                                    <Press key={c.linkText + idx}>
                                        <Image
                                            unoptimized={true}
                                            src={c.logo}
                                            alt={"logo til presseomtale"}
                                            width={100}
                                            height={50}
                                        />
                                        <PressText>
                                            <PressDescription>
                                                {ReactHtmlParser(c.text)}
                                            </PressDescription>
                                            <Link href={c.link} passHref>
                                                <AlternativLink
                                                    text={c.linkText}
                                                />
                                            </Link>
                                        </PressText>
                                    </Press>
                                );
                            })}
                        </PressWrapper>
                    )}
                </TextWrapper>
                {video && (
                    <VideoComponent
                        videoUrl={video}
                        mobileVideoUrl={mobileVideo}
                    />
                )}
                {image && (
                    <FullscreenImage
                        data={image}
                        size={{
                            width: 1920,
                            height: 700,
                            mWidth: 990,
                            mHeight: 610,
                        }}
                        nomargin={"2rem 0 0"}
                    />
                )}
                {equipment && (
                    <TripleColumnWithOvertake
                        data={equipment}
                        equipment={true}
                        url={"utstyr"}
                    />
                )}
                {overlay && (
                    <CTAwrapper onClick={() => setShowModal(!showModal)}>
                        <PrimaryButton text={"sound experience"} />
                    </CTAwrapper>
                )}
            </Wrapper>
            {showModal ? (
                <Overlay>
                    <Modal>
                        <CloseIcon onClick={() => setShowModal(!showModal)}>
                            <div />
                            <div />
                        </CloseIcon>

                        <Embed>{ReactHtmlParser(overlay.embed)}</Embed>
                    </Modal>
                </Overlay>
            ) : null}
        </>
    );
};

export default EquipmentOverall;

const Wrapper = styled.section<EquipemntOverallStyled>`
    max-width: 1920px;
    width: ${(props) => (props.narrow ? "80%" : "100%")};
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    padding-top: 1rem;
`;

const Heading = styled.h2`
    font-family: "nouvelr-bold";
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-top: 4rem;
`;

const Description = styled.p`
    font-size: 1.1rem;
    line-height: 1.3em;
    width: 100%;

    @media ${device.tablet} {
        width: 100%;
    }
    @media ${device.laptop} {
        width: 80%;
    }
`;

const PressWrapper = styled.div`
    display: flex;
    padding: 2rem 0;
    width: 100%;
    flex-direction: column;

    @media ${device.tablet} {
        width: 100%;
        flex-direction: row;
    }
    @media ${device.laptop} {
        width: 60%;
    }
`;

const Press = styled.div`
    width: 100%;
    display: flex;
    padding: 0.5rem 0;

    @media ${device.tablet} {
        width: 50%;
    }
`;

const PressText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 25px;
`;

const PressDescription = styled.p`
    padding: 0.5rem 0 0.1rem;
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
`;

const CTAwrapper = styled.div``;

const Modal = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 100;
    transition: 250ms;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const Embed = styled.div`
    @media ${device.mobileS} {
        width: 284px;
        height: 160px;
    }
    @media ${device.mobileM} {
        width: 320px;
        height: 180px;
    }
    @media ${device.mobileL} {
        width: 375px;
        height: 210px;
    }
    @media ${device.tablet} {
        width: 425px;
        height: 239px;
    }
    @media ${device.laptop} {
        width: 768px;
        height: 432px;
    }
    @media ${device.laptopM} {
        width: 1024px;
        height: 576px;
    }
    @media ${device.laptopL} {
        width: 1280px;
        height: 720px;
    }
    @media ${device.desktopS} {
        width: 1440px;
        height: 810px;
    }
`;

const CloseIcon = styled.button`
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    background: transparent;
    border: none;
    cursor: pointer;

    top: 5%;
    right: 5%;
    position: absolute;

    z-index: 101;

    width: 24px;
    height: 24px;

    div {
        width: 28px;
        height: 3px;
        background: ${(props) => props.theme.white};
        border-radius: 5px;
        transform-origin: 5px;
        position: relative;
        transition: 200ms ease;

        &:nth-child(1) {
            transform: rotate(45deg);
        }

        &:nth-child(2) {
            transform: rotate(-45deg);
        }
    }

    @media (min-width: 900px) {
        width: 45px;
        height: 45px;

        div {
            width: 43px;
            height: 3px;
        }
    }
`;
