import styled from "styled-components";
import { ButtonProps, ButtonStyledComponent } from "./SuperPrimaryButton";
import { device } from "../../layout/global-styles/device";

const Button = styled.button<ButtonStyledComponent>`
    min-width: 96px;
    font-family: "nouvelr-bold";
    color: ${(props) =>
        props.outline ? props.theme.white : props.theme.black};
    background-color: ${(props) =>
        props.outline ? "transparent" : props.theme.white};
    border: solid 1px
        ${(props) => (props.outline ? props.theme.white : props.theme.black)};
    text-decoration: none;
    text-transform: lowercase;
    width: ${(props) => (props.fill ? "100%" : "unset")};

    padding: ${(props) => (props.menu ? "10px" : "14px")};
    font-size: ${(props) => (props.menu ? "10px" : "1.1rem")};

    cursor: pointer;
    transition: 200ms ease;

    &:hover {
        background-color: ${(props) =>
            props.outline ? "transparent" : props.theme.black};
        color: ${(props) => props.theme.white};
        border: solid 1px
            ${(props) =>
                props.outline ? props.theme.primary : props.theme.black};
    }
    @media ${device.mobileS} {
        font-size: ${(props) => (props.menu ? "12px" : "1.1rem")};
        padding: 14px;
    }
`;

const SecondaryButton = ({
    text,
    menu,
    outline = false,
    fill,
}: ButtonProps) => {
    return (
        <Button menu={menu} outline={outline} fill={fill}>
            {text}
        </Button>
    );
};

export default SecondaryButton;
