import styled from "styled-components";
import PrimaryLink from "./Elements/PrimaryLink";
import Image from "next/image";
import { device } from "../layout/global-styles/device";
import { formatters } from "../lib/formatters";
import React, { useEffect, useState } from "react";
import Title from "./Elements/Title";
import ReactHtmlParser from "react-html-parser";
import { LinkData } from "../lib/LinkData";
import Link from "next/link";
import SuperPrimaryButton from "./Elements/SuperPrimaryButton";

interface IFlexWrapper {
    margin?: string;
    isPopup?: boolean;
    news?: boolean;
    frontpage?: boolean;
}
export interface TripleColumnProps {
    data: CardData[];
    id?: string;
    margin?: string;
    showLine?: Boolean;
    title?: string;
    subtitle?: string;
    openOne?: any;
    setOpenOne?: any;
    openTwo?: any;
    setOpenTwo?: any;
    openThree?: any;
    setOpenThree?: any;
    isPopup?: boolean;
    news?: boolean;
    frontpage?: boolean;
}
export interface CardData {
    image: string;
    title: string;
    text?: string;
    cta?: LinkData;
    date?: string;
}

const SectionWrapper = styled.section<IFlexWrapper>`
    display: flex;
    flex-direction: column;
`;

const FlexWrapper = styled.section<IFlexWrapper>`
    display: flex;
    max-width: 1440px;
    width: ${(props) => (props.news ? "90vw" : "70vw")};
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: ${(props) =>
        props.news ? "space-between" : "space-around"};
    margin: ${(props) => (props.margin ? props.margin : "0rem auto")};
    padding: 0;

    @media ${device.laptop} {
        flex-direction: row;
        margin: ${(props) => (props.margin ? props.margin : "0rem auto")};
    }
`;

const FlexContent = styled.div<IFlexWrapper>`
    display: flex;
    flex-direction: column;
    margin: 0% auto 2% auto;
    margin-bottom: 2rem;
    width: 100%;
    max-width: 100vw;
    max-width: 100%;
    flex-basis: 30%;
    background-color: ${(props) =>
        props.news && !props.frontpage ? "#F2F2F2" : "unset"};
    cursor: ${(props) => (props.isPopup ? "pointer" : "unset")};
    justify-content: ${(props) => (props.news ? "space-between" : "unset")};

    @media ${device.mobileL} {
        width: 33.3333%;
        width: 460px;
    }

    @media ${device.laptop} {
        margin: ${(props) => (props.news ? "0px 0px 2rem" : "0% 15px 0% 15px")};
    }
`;
const FlexItem = styled.div<IFlexWrapper>`
    margin: ${(props) => (props.frontpage ? "0" : "0% 0% 4% 0%")};
    padding: ${(props) => (props.news && !props.frontpage ? "0 0.5rem" : "0")};

    @media ${device.laptop} {
        padding: ${(props) =>
            props.news && !props.frontpage ? "0 1.5rem" : "0"};
    }
`;

const ImageDiv = styled(FlexItem)`
    position: relative;
    width: 100%;

    @media ${device.laptop} {
        max-width: 460px;
    }
`;

const Heading = styled.div<IFlexWrapper>`
    font-family: "nouvelr-bold";
    font-size: ${(props) => (props.news ? "1.2em" : "1.25rem")};
    text-align: left;
    padding: 0;

    @media ${device.tablet} {
        padding: 0;
    }
`;
const HeadingFrontpage = styled.h3`
    text-transform: uppercase;
    font-family: "nouvelr-bold";
    text-align: left;
    padding: 0;
`;
const Date = styled.p`
    display: flex;
    flex-direction: row;
    color: ${(props) => props.theme.neutral};
`;

const Marker = styled.div`
    height: 20px;
    width: 3px;
    background-color: ${(props) => props.theme.primary};
    margin-right: 10px;
`;
const Text = styled.div`
    max-width: 90%;
    padding: 0;

    .duty-text {
        font-size: 0.7rem;
        margin-top: 0.5rem;
    }

    @media ${device.tablet} {
        padding: 0;
    }
`;

const Line = styled.div`
    background-color: #fff;
    border-bottom: 3px solid white;
    width: 80%;
`;
const Subtitle = styled.div`
    color: ${(props) => props.theme.black};
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
    padding: 0 2rem 1rem;
    font-size: 1.1rem;
    span {
        font-size: 12px;
        color: ${(props) => props.theme.neutral};
    }
`;

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const TripleColumn: React.FC<TripleColumnProps> = ({
    data,
    margin,
    showLine,
    id = "",
    title = undefined,
    subtitle = undefined,
    openOne,
    setOpenOne,
    openTwo,
    setOpenTwo,
    openThree,
    setOpenThree,
    isPopup = false,
    news = false,
    frontpage = false,
}) => {
    const [index, setIndex] = useState(null);

    useEffect(() => {
        if (index === 0) {
            setOpenOne(true);
        }
        return () => {
            setIndex(null);
        };
    }, [index, openOne, setOpenOne]);

    useEffect(() => {
        if (index === 1) {
            setOpenTwo(true);
        }
        return () => {
            setIndex(null);
        };
    }, [index, openTwo, setOpenTwo]);

    useEffect(() => {
        if (index === 2) {
            setOpenThree(true);
        }
        return () => {
            setIndex(null);
        };
    }, [index, openThree, setOpenThree]);

    return (
        <SectionWrapper id={id} margin={margin}>
            {title && <Title heading={title} />}
            {subtitle && <Subtitle>{ReactHtmlParser(subtitle)}</Subtitle>}
            <FlexWrapper news={news} margin={margin}>
                {data.map((c, idx) => (
                    <FlexContent
                        key={c.title}
                        onClick={() => (isPopup ? setIndex(idx) : null)}
                        isPopup={isPopup}
                        news={news}
                        frontpage={frontpage}
                    >
                        <ImageDiv>
                            <Image
                                objectFit="cover"
                                unoptimized={true}
                                alt={formatters.stripHtmlTags(c.title)}
                                src={c.image}
                                placeholder="blur"
                                blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                                width={460}
                                height={280}
                            />
                        </ImageDiv>
                        {frontpage && (
                            <FlexItem>
                                <Date>
                                    <Marker />
                                    {ReactHtmlParser(c.date)}
                                </Date>
                            </FlexItem>
                        )}
                        {!frontpage && (
                            <FlexItem news={news}>
                                <Heading news={news}>
                                    {ReactHtmlParser(c.title)}
                                </Heading>
                            </FlexItem>
                        )}
                        {frontpage && (
                            <FlexItem news={news} frontpage={frontpage}>
                                <HeadingFrontpage>
                                    {ReactHtmlParser(c.title)}
                                </HeadingFrontpage>
                            </FlexItem>
                        )}
                        {c.text && (
                            <FlexItem news={news} frontpage={frontpage}>
                                <Text>{ReactHtmlParser(c.text)}</Text>
                            </FlexItem>
                        )}
                        {showLine && (
                            <FlexItem>
                                <Line />
                            </FlexItem>
                        )}
                        {c.cta && (
                            <FlexItem news={news} frontpage={frontpage}>
                                <PrimaryLink
                                    text={c.cta.text || "les mer"}
                                    href={c.cta.href}
                                />
                            </FlexItem>
                        )}
                    </FlexContent>
                ))}
            </FlexWrapper>
            {frontpage && (
                <Center>
                    <Link href="/nyheter" passHref>
                        <a>
                            <SuperPrimaryButton text={"les flere"} />
                        </a>
                    </Link>
                </Center>
            )}
        </SectionWrapper>
    );
};

export default TripleColumn;
