import React from "react";
import styled from "styled-components";
import { device } from "../layout/global-styles/device";
import Title from "./Elements/Title";

interface IComponentProps {
    heading?: string;
    subheading?: string;
    videoUrl: string;
    posterUrl?: string;
    mobileVideoUrl?: string;
    ctaText?: string;
    fullWidth?: boolean;
    id?: string;
}

interface StyledVideoComponent {
    fullWidth?: boolean;
    mobile?: boolean;
}

const VideoComponent = ({
    heading,
    subheading,
    videoUrl,
    posterUrl,
    mobileVideoUrl,
    fullWidth,
    id,
}: IComponentProps) => {
    return (
        <ViderComponentWrapper fullWidth={fullWidth} id={id}>
            {heading && (
                <Title heading={heading ?? "Kort presentasjon av bilen"} />
            )}
            {subheading && (
                <DescriptionText>{subheading ?? ""}</DescriptionText>
            )}

            {posterUrl && (
                <Video fullWidth={fullWidth} mobile={false}>
                    <video playsInline controls poster={posterUrl}>
                        <source src={videoUrl} type="video/mp4" />
                    </video>
                </Video>
            )}
            {!posterUrl && (
                <Video fullWidth={fullWidth} mobile={false}>
                    <video playsInline autoPlay muted loop>
                        <source src={videoUrl} type="video/mp4" />
                    </video>
                </Video>
            )}
            {posterUrl && mobileVideoUrl && (
                <Video fullWidth={fullWidth} mobile={true}>
                    <video playsInline controls poster={posterUrl}>
                        <source src={mobileVideoUrl} type="video/mp4" />
                    </video>
                </Video>
            )}
            {!posterUrl && mobileVideoUrl && (
                <Video fullWidth={fullWidth} mobile={true}>
                    <video playsInline autoPlay muted loop>
                        <source src={mobileVideoUrl} type="video/mp4" />
                    </video>
                </Video>
            )}
        </ViderComponentWrapper>
    );
};

export default VideoComponent;

const ViderComponentWrapper = styled.section<StyledVideoComponent>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: ${(props) => (props.fullWidth ? "1920px" : "1440px")};
    margin: 0 auto;
`;

const DescriptionText = styled.p`
    max-width: 70%;
    text-align: center;
    color: ${(props) => props.theme.darker};
    font-size: 1.1rem;
`;

const Video = styled.div<StyledVideoComponent>`
    display: ${(props) => (props.mobile ? "block" : "none")};
    width: 100%;
    max-width: 100%;
    margin: ${(props) => (props.fullWidth ? "0 auto" : "2rem auto;")};

    @media ${device.tablet} {
        display: ${(props) => (props.mobile ? "none" : "block")};
    }

    video {
        object-fit: cover;
        min-height: 15rem;
        width: 100%;

        @media ${device.mobileL} {
            min-height: 25rem;
        }
    }
`;
